import {
  bcDateTimeUTCFormat,
  NEW_DATE_FORMAT,
} from "../../../../utils/helpers/dateTimePickerHelpers";
import { BcDateTimePicker } from "../../../datepicker/BcDateTimePicker";
import DraftBunkersFreshWater from "../DraftBunkersFreshWater/DraftBunkersFreshWater";
import dayjs from "dayjs";
import { Alert, Box, Stack, Typography } from "@mui/material";
import Draft from "../DraftBunkersFreshWater/Draft";
import ObservedDistanceSection from "../ObservedDistanceSection/ObservedDistanceSection";

const DepartureSectionMui = ({
  archivedPortcall,
  portCallState,
  isHighlightedField,
  editMode,
  setStartOfSeaPassage,
  currentPortcallErrors,
  setDepartureDraft,
  isLoading,
  setBunkersFuel,
  setFreshWater,
  setEtd,
  setAtd,
  setFieldValue,
  errors,
  setDepartureTugs,
}) => {
  const {
    etd,
    startOfSeaPassage,
    departureDraft,
    // departurePortName,
    bunkersRemainingOnBoardOnDeparture,
    freshWaterOnDeparture,
    observedDistanceMandatory,
    observedInPortDistance,
    departureTugs,
    atd,
  } = portCallState;

  const atdOrEtdInUTC = atd || etd ? dayjs(atd ?? etd).utc() : null;

  return (
    <>
      {/*{modalIsOpen && (*/}
      {/*  <BcModal*/}
      {/*    setShowModal={setModalIsOpen}*/}
      {/*    modalName="ETD Accepted"*/}
      {/*    modalBody={*/}
      {/*      <div className="d-flex flex-column">*/}
      {/*        <div className="mb-2 p-1">*/}
      {/*          <table className="table">*/}
      {/*            <tbody>*/}
      {/*              <tr>*/}
      {/*                <td className="td-padding">Old ETD</td>*/}
      {/*                <td className="td-padding">*/}
      {/*                  {bcDateTimeFormat(*/}
      {/*                    portCallEtaResult?.reportingEta,*/}
      {/*                    WITH_TIME_FORMAT,*/}
      {/*                    "Not provided"*/}
      {/*                  )}*/}
      {/*                </td>*/}
      {/*              </tr>*/}
      {/*              <tr>*/}
      {/*                <td className="td-padding">Updated at of old ETD</td>*/}
      {/*                <td className="td-padding">*/}
      {/*                  {bcDateTimeFormat(*/}
      {/*                    portCallEtaResult?.reportingEtaUpdatedAt,*/}
      {/*                    WITH_TIME_FORMAT,*/}
      {/*                    "Not provided"*/}
      {/*                  )}*/}
      {/*                </td>*/}
      {/*              </tr>*/}
      {/*              <tr>*/}
      {/*                <td className="td-padding">New ETD</td>*/}
      {/*                <td className="td-padding">*/}
      {/*                  {bcDateTimeUTCFormat(*/}
      {/*                    portCallEtaResult?.actualEta,*/}
      {/*                    WITH_TIME_FORMAT,*/}
      {/*                    "Not provided"*/}
      {/*                  )}*/}
      {/*                </td>*/}
      {/*              </tr>*/}
      {/*              <tr>*/}
      {/*                <td className="td-padding">Updated at of new ETD</td>*/}
      {/*                <td className="td-padding">*/}
      {/*                  {bcDateTimeFormat(*/}
      {/*                    portCallEtaResult?.actualEtaUpdatedAt,*/}
      {/*                    WITH_TIME_FORMAT,*/}
      {/*                    "Not provided"*/}
      {/*                  )}*/}
      {/*                </td>*/}
      {/*              </tr>*/}
      {/*              <tr>*/}
      {/*                <td className="td-padding">Laycan end</td>*/}
      {/*                <td className="td-padding">*/}
      {/*                  {bcDateTimeUTCFormat(*/}
      {/*                    portCallEtaResult?.earliestLaycanEnd,*/}
      {/*                    WITH_TIME_FORMAT,*/}
      {/*                    "Not provided"*/}
      {/*                  )}*/}
      {/*                </td>*/}
      {/*              </tr>*/}
      {/*            </tbody>*/}
      {/*          </table>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    }*/}
      {/*  />*/}
      {/*)}*/}
      <Stack
        sx={{
          px: "20px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            mt: "20px",
            mb: editMode ? 0 : "15px",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              gridColumn: 1,
            }}
          >
            Departure
          </Typography>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          {(!startOfSeaPassage || archivedPortcall) && (
            <>
              <Box
                sx={{
                  mb: "8px",
                }}
              >
                {!editMode && (
                  <Typography variant="body1">
                    {!atd ? "ETD" : "ATD"}
                  </Typography>
                )}

                {editMode ? (
                  <>
                    <Box mt="23px" width="236px">
                      <BcDateTimePicker
                        label={!atd ? "ETD" : "ATD"}
                        withTimeInput
                        positions={["top", "end"]}
                        selectedDate={atdOrEtdInUTC}
                        utc
                        required
                        uneditableDatePicker={Boolean(atd)}
                        errors={errors}
                        fieldName="etd"
                        onDatePickerClose={(newDate) => {
                          if (!atd) {
                            setEtd(newDate);
                          } else {
                            setAtd(newDate);
                          }
                        }}
                      />
                    </Box>
                    {archivedPortcall &&
                      currentPortcallErrors?.startOfSeaPassage && (
                        <Alert severity="error">
                          {currentPortcallErrors?.startOfSeaPassage}
                        </Alert>
                      )}
                  </>
                ) : (
                  <Typography
                    component="span"
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      mt: "12px",
                    }}
                  >
                    <Typography variant="body2">
                      {bcDateTimeUTCFormat(
                        atdOrEtdInUTC,
                        NEW_DATE_FORMAT,
                        "Not provided"
                      )}
                    </Typography>
                  </Typography>
                )}
              </Box>
            </>
          )}
          <Box
            sx={{
              mb: "8px",
            }}
            className={`${
              isHighlightedField("startOfSeaPassage") && "pulse-background"
            }`}
          >
            {!editMode && (
              <Typography variant="body1">Start of Sea Passage</Typography>
            )}

            {editMode ? (
              <Box mt="23px" width="236px">
                <BcDateTimePicker
                  label="Start of Sea Passage"
                  withTimeInput
                  selectedDate={startOfSeaPassage}
                  onDatePickerClose={(newDate) => {
                    setStartOfSeaPassage(newDate);
                  }}
                  positions={["top", "end"]}
                  utc
                />
              </Box>
            ) : (
              <Typography
                variant="body2"
                sx={{
                  mt: "12px",
                }}
              >
                {bcDateTimeUTCFormat(
                  startOfSeaPassage ? dayjs(startOfSeaPassage).utc() : null,
                  NEW_DATE_FORMAT,
                  "Not provided"
                )}
              </Typography>
            )}
          </Box>
          <ObservedDistanceSection
            editMode={editMode}
            fieldName="observedInPortDistance"
            value={observedInPortDistance}
            setFieldValue={setFieldValue}
            observedDistanceMandatory={observedDistanceMandatory}
            inPort
            errors={errors}
          />
          <Draft
            setDraft={setDepartureDraft}
            draft={departureDraft}
            editMode={editMode}
            isLoading={isLoading}
          />
        </Box>

        <DraftBunkersFreshWater
          header="On departure"
          isArrival={false}
          editMode={editMode}
          isLoading={isLoading}
          draft={departureDraft}
          setDraft={setDepartureDraft}
          bunkers={bunkersRemainingOnBoardOnDeparture}
          setBunkersFuel={setBunkersFuel}
          freshWater={freshWaterOnDeparture}
          setFreshWater={setFreshWater}
          tugs={departureTugs}
          setTugs={setDepartureTugs}
          errorsStateForTugs={currentPortcallErrors.departureTugs}
        />
      </Stack>
    </>
  );
};

export default DepartureSectionMui;
